.table-of-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px; /* Adds more space between items */
    margin-top: 30px; /* Adds extra space above the list */
  }
  
  .table-of-contents a {
    color: #b8b0a8;
    text-decoration: none;
    font-size: 1.1em;
    position: relative;
    padding-left: 25px; /* Slightly more padding for the dash */
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px; /* Adds spacing between letters for a sleeker look */
    transition: color 0.3s ease;
  }
  
  .table-of-contents a:hover,
  .table-of-contents a.active {
    color: #f5e6dc; /* Color for active and hover states */
    font-weight: 500;
  }
  
  .table-of-contents a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px; /* Thin line for a subtle, sleek look */
    height: 2px; /* Slightly thinner for elegance */
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .table-of-contents a.active::before,
  .table-of-contents a:hover::before {
    background-color: #f5e6dc; /* Line color for active and hover */
  }
  