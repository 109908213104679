.experience-list {
    padding: 20px;
}

.experience-item {
    background-color: #1a1a1a; /* Dark black-grey for card background */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: transform 0.2s, box-shadow 0.3s ease-in-out;
    box-shadow: 0 0 0 transparent;
}

.experience-item:hover {
    transform: translateY(-4px);
    box-shadow: 0px 4px 20px rgba(245, 230, 220, 0.2); /* Soft beige glow on hover */
}

.date {
    font-size: 0.9em;
    color: #f5e6dc; /* Beige for dates */
}

h3 {
    color: #f5e6dc; /* Beige headers */
    font-size: 1.5em;
    margin: 10px 0;
}

p {
    color: #d0c9c0; /* Softer beige-grey for paragraph text */
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 15px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
