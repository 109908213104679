.section {
    padding: 0px;
    margin-bottom: 150px;
    color: #a8b2d1;
    border-bottom: 1px solid #2a2a2a;
}

.section h2 {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 10px;
}
