.projects-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.project-card {
    display: flex;
    align-items: center;
    background-color: #1c1c1c; /* Deep dark grey for subtle contrast */
    border-radius: 8px;
    padding: 20px;
    text-decoration: none;
    color: #eae0d5; /* Off-white for readability */
    transition: transform 0.2s, box-shadow 0.3s ease-in-out;
    box-shadow: 0 0 0 transparent;
}

.project-card:hover {
    transform: translateY(-4px);
    box-shadow: 0px 4px 20px rgba(150, 150, 150, 0.3); /* Soft light grey glow */
}

.project-image {
    width: 120px;
    height: 80px;
    margin-right: 20px;
    border-radius: 4px;
}

.project-content p {
    color: #b0b0b0; /* Soft grey for paragraph text */
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

/* Tags styling */
.tags span {
    background-color: #3c4a5b; /* Cool grey-blue to match Experience tags */
    color: #eae0d5; /* Off-white text color */
    font-size: 0.9em;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.tags span:hover {
    background-color: #eae0d5;
    color: #3c4a5b;
}

