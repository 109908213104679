/* src/components/SocialIcons.css */

.social-icons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    position: absolute;
    bottom: 10vh;
}

.icon {
    width: 24px;
    height: 24px;
    fill: #b8b0a8;
    transition: fill 0.3s, filter 0.3s;
}

/* Hover effect for SVG icons */
.icon:hover {
    fill: #ffd3b6;
}

/* Hover effect for the LeetCode image */
.icon[src*="leetcode.svg"]:hover {
    filter: brightness(0) saturate(100%) invert(92%) sepia(8%) saturate(650%) hue-rotate(300deg) brightness(96%) contrast(90%);
}
