/* App.css */
body {
  font-family: 'Inter', sans-serif;
  background-color: #121212; /* Dark black for main background */
  color: #e4ded7; /* Subtle beige for main text */
  font-size: 16px;
  margin: 0;
  display: flex;
  justify-content: center;
}

/* Container for the layout */
.app-container {
  display: flex;
  max-width: 1400px;
  width: 100%;
}

/* Left Column */
.left-column {
  position: relative;
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #e4ded7;
}

/* Content (Right Column) */
.content {
  position: relative;
  top: 10vh;
  width: 50vw;
  /* display: flex; */


}
/* Sidebar (Left Column) */
.sidebar {
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #e4ded7;
  padding-left: 30px;
  /* padding-right: 30px; */
}

.sidebar__inner {
  position: relative;
  padding-top: 10vh;
  height: 100vh;
  width: 600px;
  padding-bottom: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #e4ded7;
}

.sidebar h1 {
  font-size: 3.2em;
  margin: 0;
  color: #fff5e1; /* Softer beige for title */
}

.sidebar p {
  font-size: 1.2em;
  color: #b8b0a8; /* Light beige-grey for subtitle */
  margin: 10px 0 30px;
  max-width: 300px;
}


section {
  border-bottom: 1px solid #3e3e3e; /* Darker grey divider line */
  scroll-margin-top: 80px; /* Adjust this value to control the offset */
  
}

h2 {
  color: #fff5e1; /* Soft beige headers */
  font-size: 2em;
  margin-bottom: 10px;
}

h3 {
  color: #fff5e1; /* Soft beige headers */
  margin-bottom: 10px;
}

/* Optional: Smooth scrolling */
html {
  scroll-behavior: smooth;
}

#about p {
  margin: 0;
}

#about {
  padding: 0px 30px 0px 2530pxpx;
  max-width: 800px;
  color: #dcd2c5; /* Light beige for main about text */
}

#experience {
  padding: 0px 30px 0px 30px;

}
#projects {
  padding: 0px 30px 0px 30px;

}
#contact {
  padding: 0px 30px 0px 30px;

}
#about h2 {
  color: #fff5e1;
  font-size: 2.5em;
  margin-bottom: 20px;
}

#about p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #b8b0a8;
}

.tags span {
  background-color: #484848; /* Dark grey for tag background */
  color: #e4ded7; /* Light beige for tag text */
  font-size: 0.9em;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.tags span:hover {
  background-color: #e4ded7;
  color: #484848;
}

#contact  {
  color: #fff5e1;
  margin-bottom: 20px;
  height: 100vh;
}

/* Responsive Styles for smaller screens */
@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
    max-width: 100%;
  }

  .left-column {
    width: 100%;
    height: auto;
  }

  /* Make the sidebar relative instead of fixed on mobile */
  .sidebar {
    position: relative;
    height: auto;
    width: 100%;
  }

  .sidebar__inner {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
  }

  .sidebar h1 {
    font-size: 2.5em;
    text-align: center;
  }

  .sidebar p {
    font-size: 1em;
    text-align: center;
    max-width: 100%;
  }

  .content {
    width: 100%;
    top: 0;
    padding: 20px;
  }

  section {
    padding: 20px 0;
    border-bottom: 1px solid #3e3e3e;
  }

  #about {
    padding: 0 20px;
  }
}
